// nationalities.js
const nationalities = [
    "Afghan",
    "Albanais",
    "Algérien",
    "Américain",
    "Andorran",
    "Angolais",
    "Argentin",
    "Arménien",
    "Australien",
    "Autrichien",
    "Azerbaïdjanais",
    "Bahaméen",
    "Bahraini",
    "Bangladais",
    "Barbadien",
    "Biélorusse",
    "Belge",
    "Bélizien",
    "Béninois",
    "Bhoutanais",
    "Bolivien",
    "Bosnien",
    "Botswanais",
    "Brésilien",
    "Britannique",
    "Brunéien",
    "Bulgare",
    "Burkinabé",
    "Birman",
    "Burundais",
    "Cambodgien",
    "Camerounais",
    "Canadien",
    "Cap-Verdien",
    "Centrafricain",
    "Tchadien",
    "Chilien",
    "Chinois",
    "Colombien",
    "Comorien",
    "Congolais (Brazzaville)",
    "Congolais (Kinshasa)",
    "Costaricien",
    "Croate",
    "Cubain",
    "Chypriote",
    "Tchèque",
    "Danois",
    "Djiboutien",
    "Dominicain",
    "Néerlandais",
    "Est-Timorais",
    "Équatorien",
    "Égyptien",
    "Salvadorien",
    "Émirati",
    "Guinéen équatorial",
    "Érythréen",
    "Estonien",
    "Éthiopien",
    "Fidjien",
    "Finlandais",
    "Français",
    "Gabonais",
    "Gambien",
    "Géorgien",
    "Allemand",
    "Ghanéen",
    "Grec",
    "Grenadien",
    "Guatémaltèque",
    "Guinéen",
    "Guinéen-Bissauan",
    "Guyanien",
    "Haïtien",
    "Hondurien",
    "Hongrois",
    "Islandais",
    "Indien",
    "Indonésien",
    "Iranien",
    "Irakien",
    "Irlandais",
    "Israélien",
    "Italien",
    "Ivoirien",
    "Jamaïcain",
    "Japonais",
    "Jordanien",
    "Kazakh",
    "Kenyan",
    "Kiribatien",
    "Kosovar",
    "Koweïtien",
    "Kirghize",
    "Laotien",
    "Letton",
    "Libanais",
    "Lésothan",
    "Libérien",
    "Libyen",
    "Liechtensteinois",
    "Lituanien",
    "Luxembourgeois",
    "Macédonien",
    "Malagasy",
    "Malawien",
    "Malaisien",
    "Maldivien",
    "Malien",
    "Maltais",
    "Maréchallais",
    "Mauritanien",
    "Mauricien",
    "Mexicain",
    "Micronésien",
    "Moldave",
    "Monégasque",
    "Mongol",
    "Monténégrin",
    "Marocain",
    "Mozambicain",
    "Namibien",
    "Nauruan",
    "Népalais",
    "Néo-Zélandais",
    "Nicaraguayen",
    "Nigérian",
    "Nigerien",
    "Nord-Coréen",
    "Norvégien",
    "Omanais",
    "Pakistanais",
    "Palauan",
    "Palestinien",
    "Panaméen",
    "Papouan-Néo-Guinéen",
    "Paraguayen",
    "Péruvien",
    "Polonais",
    "Portugais",
    "Qatarien",
    "Roumain",
    "Russe",
    "Rwandais",
    "Saint-Christophe-et-Niévès",
    "Saint-Lucien",
    "Salvadorien",
    "Samoan",
    "Saint-Marinais",
    "Santoméen",
    "Saoudien",
    "Sénégalais",
    "Serbe",
    "Seychellois",
    "Sierra-Léonais",
    "Singapourien",
    "Slovaque",
    "Slovène",
    "Salomonien",
    "Somalien",
    "Sud-Africain",
    "Sud-Coréen",
    "Sud-Soudanais",
    "Espagnol",
    "Sri-Lankais",
    "Soudanais",
    "Surinamien",
    "Swazi",
    "Suédois",
    "Suisse",
    "Syrien",
    "Taïwanais",
    "Tadjik",
    "Tanzanien",
    "Thaïlandais",
    "Togolais",
    "Tongien",
    "Trinidadien ou Tobagonien",
    "Tunisien",
    "Turc",
    "Turkmène",
    "Tuvaluan",
    "Ougandais",
    "Ukrainien",
    "Uruguayen",
    "Ouzbek",
    "Vanuatuan",
    "Vénézuélien",
    "Vietnamien",
    "Yéménite",
    "Zambien",
    "Zimbabwéen"
];

export default nationalities