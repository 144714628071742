<template>

    <nav class="navbar navbar-expand-lg navbar-light shadow-sm" style="z-index: 1;background-color: white;">
        <div class="container-fluid">
          <router-link style="margin-top: -6px;" class="navbar-brand" to="/"><img src="../assets/logo1.png" style="height: 50px;width:120px"></router-link>
          <button class="navbar-toggler" style="border:none" type="button" @click="toggleOverlay"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars" style="color:orange"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent" style="position: absolute;right: 0;">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link class="nav-link" to="/detailsBien" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >Simuler un prêt</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/faq" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >FAQ</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="overlay container-fluid" :class="{ active: isOverlay1Active }" style='overflow-y:auto;width: 100vw;' >
    <div class="container mt-5" style="display:flex;justify-content:end">
      <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay1"><i class="fa fa-close"></i></button>
    </div>
    <router-link style="font-size: 32px;color: orange;font-family:'Caveat'" class="navbar-brand" to="/">Crefit</router-link>
    <div class="container" style='overflow-y:auto;text-align:start'>
    <hr style="margin-top: 10px; color: #DCDCDC;">
    <h3 style="font-size:30px;text-decoration: underline;">Conditions Générales de Prêt aux Étudiants</h3>
    <p>Bienvenue sur notre plateforme de prêt dédiée aux étudiants. Veuillez lire attentivement les conditions générales suivantes avant de soumettre votre demande de prêt en ligne. Ces conditions s'appliquent à tous les emprunteurs.</p>
    <h4 style="font-weight: bold;font-size:25px">1. Admissibilité au Prêt</h4>
    <div>Le prêt est ouvert aux étudiants boursiers et non boursiers selon les critères suivants :</div>
        <div><p style="color:orange">Étudiants Boursiers :</p>
            <div>
                <div>Fournir une attestation de bourse délivrée par l'AMCI.</div>
                <div>Fournir une copie de votre passeport.</div>
                </div>
              </div>
        <div><p style="color:orange">Étudiants Non Boursiers :</p>
            <div>
                <div>Fournir une copie de votre passeport.</div>
                <div>Déposer un objet de valeur (ordinateur portable, téléviseur, téléphone) en guise de garantie.</div>
            </div>
        </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">2. Procédure de Demande de Prêt</h4>
        <div><p style="color:orange">Remplir le Formulaire en Ligne :</p>
            <div>
                <div>Complétez le formulaire de demande de prêt disponible sur notre site web avec toutes les informations requises.</div>
            </div>
        </div>
        <div><p style="color:orange">Prise de Contact :</p>
            <div>
                <div>Après soumission de votre formulaire, notre équipe vous contactera par WhatsApp pour organiser un rendez-vous.</div>
            </div>
        </div>
        <div><p style="color:orange">Rendez-vous pour Dépôt des Documents :</p>
            <div>
                <div>Lors du rendez-vous, vous devrez présenter les documents requis :
                    <div>
                        <div>Pour les boursiers : attestation de bourse et passeport.</div>
                        <div>Pour les non boursiers : passeport et objet de valeur.</div>
                    </div>
                </div>
                <div>Nos agents vérifieront les documents et/ou les objets déposés.</div>
            </div>
        </div>
        <div><p style="color:orange">Virement Bancaire :</p>
            <div>
                <div>Une fois les documents et/ou objets vérifiés, nous effectuerons un virement bancaire sur votre compte CIH dans les plus brefs délais.</div>
            </div>
        </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">3. Modalités de Remboursement</h4>
    <div>
        <div><p style="color:orange">Date de Remboursement :</p>
            <div>
                <div>La date de remboursement est celle que vous aurez sélectionnée lors de votre demande en ligne.</div>
            </div>
        </div>
        <div><p style="color:orange">Procédure de Remboursement :</p>
            <div>
                <div>Effectuez un virement bancaire du montant du prêt sur notre compte.</div>
                <div>Si le remboursement est effectué avant la date de remboursement prévue, le montant des intérêts à payer sera réduit.</div>
                <div>Après réception et confirmation du paiement, nous organiserons un rendez-vous pour vous restituer votre passeport et/ou objet de valeur.</div>
            </div>
        </div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">4. Pénalités de Retard</h4>
    <div>
        <div>Si vous dépassez la date de remboursement, des pénalités de retard seront appliquées. Le montant des pénalités sera calculé en fonction du nombre de jours de retard.</div>
        <div>Les détails des pénalités seront affichés lors de la simulation de prêt sur notre site web.</div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">5. Simulation de Prêt</h4>
    <div>
        <div>Avant de soumettre votre demande, utilisez notre outil de simulation de prêt pour estimer le montant total à rembourser, y compris les éventuelles pénalités en cas de retard.</div>
        <div>La simulation vous permettra de mieux comprendre les conditions financières de votre prêt.</div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">6. Assistance et Contact</h4>
    <div>
        <div>Pour toute question ou assistance supplémentaire, veuillez nous contacter par email à crefitfacile@gmail.com ou par téléphone au +212 0632033353.</div>
    </div>

    <p>En soumettant votre demande de prêt, vous acceptez les présentes conditions générales. Nous nous engageons à traiter vos informations avec la plus grande confidentialité et à vous offrir un service de qualité.</p>
    <hr>
    </div>
    </div>


      <div class="overlay" :class="{ active: isOverlayActive }">
        <div class="container mt-5" style="display:flex;justify-content:end">
          <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay"><i class="fa fa-close"></i></button>
        </div>
        <router-link style="font-size: 32px;color: orange;font-family:'Caveat'" class="navbar-brand" to="/">Crefit</router-link>
        <div class="content">
          <div class="button-container">
            <router-link class="nav-link" @click="closeOverlay" to="/detailsBien" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white">Simuler un prêt </router-link>
        </div>
        <br>
        <div class="button-container">
          <router-link class="nav-link" @click="closeOverlay" to="/faq" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white" >FAQ</router-link>
        </div>
        <hr style="margin-top: 50px; color: #DCDCDC;">
        <div class="content">
          Nous comprenons les défis uniques auxquels vous faites face et sommes ici pour vous offrir une solution financière adaptée à vos besoins.
        </div>
        </div>
    </div>
    <div class="container" style="text-align: center;font-size: 45px;margin-top:5vh;color: orange;">Merci {{ prenom }}</div>
      <div class="row container-fluid " style="margin-top:3vh;margin-bottom:1vh" >
            <div class="col-md-8 container"  >
              <div class="card d-flex justify-content-center " style="background-color:rgba(0, 255, 0, 0.2);font-size: 20px ;margin-bottom:50px;">
                <div class="card-body" >
                  <h5 style="font-size: 26px;color: green;font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">VALIDE<i class="fa fa-check"></i></h5>
                  <hr>
                  <div style="font-size: 15px;color: black;font-family:'Kanit';">
                    Merci pour votre confiance et d'avoir soumis votre demande de crédit. Nous tenons à vous informer que votre demande a bien été reçue et est actuellement en cours de traitement par notre équipe.

                    Dans le cadre du traitement de votre crédit, nous allons examiner attentivement toutes les informations fournies.

                    Nous comprenons l'importance de votre demande et nous nous engageons à vous fournir une réponse dans les plus brefs délais. Habituellement, le délai de réponse est de 24 heures ouvrables. Cependant, veuillez noter que dans certains cas, ce délai peut être légèrement prolongé en raison de la complexité de votre dossier ou de la nécessité de collecter des informations supplémentaires.
                    
                  </div>
                </div>
              </div>
            </div>
    </div>
    <br>
    <div class="row container-fluid " style="margin-top:1vh;margin-bottom:15vh" >
            <div class="col-md-8 container"  >
              <div class="card d-flex justify-content-center " style="background-color:rgba(255, 255, 0, 0.2);font-size: 20px ;margin-bottom:50px;">
                <div class="card-body" >
                  <h5 style="font-size: 26px;color: red;font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">A SAVOIR <i class="fa fa-warning"></i></h5>
                  <hr>
                  <div style="font-size: 15px;color: black;font-family:'Kanit';">
                    <div style="font-size: 20px;">Nous tenons à vous rappeler qu'un taux d'intérêt de <span style="color: red;">+5% </span>sera ajouté chaque semaine au delà du délai de paiement.</div>
                  </div>
                </div>
              </div>
            </div>
    </div>
    
    <section class='container-fluid' style='width:100vw;background-color:black'>
  <footer class="footer-fluid" style="width: 100vw;background-color: black;margin-left: -12px;padding-top: 20px;color: white;padding-left: 10px;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" style='text-align:start'>
        <span style='color:orange;font-size:30px;font-weight:bold'>Créfit</span><br>
        Notre plateforme offre des solutions de prêt adaptées à vos besoins spécifiques, avec une limite de 800 dirhams pour les étudiants et de 1000 dirhams pour les non-étudiants. Nous comprenons que chaque situation est unique, c'est pourquoi les conditions de prêt peuvent varier d'une personne à l'autre, garantissant une flexibilité qui répond à vos circonstances individuelles. De plus, le taux d'intérêt sur les prêts est ajusté en fonction de chaque situation, vous assurant des conditions transparentes et équitables. Profitez d'un service personnalisé qui s'adapte à vos besoins pour vous soutenir dans vos projets financiers.
        <button  type="button" class="detail btn btn b-prof" @click="toggleOverlay1" style="color:orange;">Nous vous conseillons de voir les conditions.</button>
        <p>Vous pouvez simuler un prêt en cliquant <router-link class="" style='color:orange' to="/detailsBien">ici </router-link></p>
      </div>
      <div class="">
        <div class="contact-item">
          <div class="" style="padding-right: 30px;font-size:8px">
            <i class="fas fa-phone-alt"></i>
            <span style="font-size: 10px;">+212 0632033353</span>
          </div>
          <div>
            <i class="fas fa-envelope"></i>
            <span style="font-size: 10px;">crefitfacile@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</footer>

</section>
    </template>
    
    <script>
    import emailjs from 'emailjs-com' 
    import nationalities from '../assets/nationalites.js'
    export default {
      data() {
        return {
          isOverlay1Active:false,
          nom: '',
          prenom: '',
          message:null,
          state:null,
          parsedLast:null,
    };
      },
      mounted(){
      
        this.scrollToTop()
        window.scrollTo(0,0);

        const nom_prenom=localStorage.getItem('nom_prenom')
        if(nom_prenom){
        this.parsedLast = JSON.parse(nom_prenom);
        this.nom=this.parsedLast['nom']
        this.prenom=this.parsedLast['prenom']
        }
      },
      methods: {
        toggleOverlay1() {
      this.isOverlay1Active = !this.isOverlay1Active;
    },
    closeOverlay1() {
      this.isOverlay1Active = false;
    },
        toggleOverlay() {
          this.isOverlayActive = !this.isOverlayActive;
        },
    tofaq(){
      this.$router.push({name:'faq'})
    },
        closeOverlay() {
          this.isOverlayActive = false;
        },
          scrollToTop() {
            window.scrollTo(0,0);
        },
      }
    }
    </script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Kanit');


.overlay::-webkit-scrollbar {
  width: 0px; /* Masquer la barre de défilement verticale */
  height: 0px; /* Masquer la barre de défilement horizontale */
}
.overlay {
  position: fixed;
  top: 0;
  left: -102%; /* Position initiale en dehors de l'écran */
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: left 0.5s ease; /* Transition de la propriété 'left' */
}

.overlay.active {
  left: 0; /* Position de la gauche de l'écran */
}

.overlay .content {
  padding: 20px;
}
em{
  font-weight:bold;
  color:orange
}
        .btn-primary{
        background-color: orange;
        color: white;
        border: none;
      }
      .btn-primary:hover{
        scale: 1.1;
        transition:0.3s;
        background-color: orange;
      }
      .valeur{
        text-align: end;
        font-weight: bold;
      }
      .accroche{
        text-align: start;
        font-size: 40px;
        font-weight: bold;
        width: 70%;
      }
      .petit_titre{
        text-align: start;
        font-weight: bold;
        font-size: 20px;
        margin-top: 30px;
      }
      .info-entoure{
        border-radius: 40px;
        border: 1px solid #d9d9d9;
        text-align: start;
        width: fit-content;
        padding: 10px;
        margin-bottom: 20px;
      }
      .fixed {
      position: sticky;
      z-index: -12;
      top: 150px; /* Ajustez la valeur en fonction de votre mise en page */
    }
    .card-image {
          position: relative; /* Position relative pour le positionnement absolu du texte */
          overflow: hidden;
          border-radius: 30px;
        }
      .card-image img {
        width: 400px; /* Utilise toute la largeur disponible */
        height: 300px; /* Ajuste automatiquement la hauteur en fonction de la largeur */
        object-fit: cover;
        border-radius: 30px;
        transition: transform 0.3s;
      }
      .card-image:hover img {
          transform: scale(1.1); /* Zoom sur l'image lorsqu'elle est survolée */
        }
        .card-title {
        position: absolute; /* Position absolue pour superposer le texte sur l'image */
        bottom: 25px; /* Marge inférieure par rapport au bas de l'image */
        left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
        color: white; /* Couleur du texte */
        font-size: 24px; /* Taille de la police */
        font-weight: bold; /* Gras */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
      }
      .card-subtitle {
        position: absolute; /* Position absolue pour superposer le texte sur l'image */
        bottom: 15px; /* Marge inférieure par rapport au bas de l'image */
        left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
        color: white; /* Couleur du texte */
        font-size: 24px; /* Taille de la police */
        font-weight: bold; /* Gras */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
      }
    
      .nav-link{
      color: rgb(0, 0, 0);
    }
    .navbar-brand{
      color: rgb(0, 0, 0);
      font-size: 32px;
    }
    .navbar-brand:hover{
      color: orange;
      font-size: 32px;
    }
    .nav-link{
      font-weight: bold;
    }
    .nav-link:hover{
      background-color: white;
    
    }
    .titre{
      padding-top: 50px;
      font-size: 35px;
      color: rgb(0, 0, 0);
      font-family: monospace;
    }
    nav.scrolled {
        background-color: rgba(255, 255, 255, 0.9); /* Fond de la barre de navigation après le défilement */
    }
      </style>