<template>
    <nav class="navbar navbar-expand-lg navbar-light shadow-sm" style="z-index: 1;background-color: white;">
    <div class="container-fluid">
      <router-link style="margin-top: -6px;" class="navbar-brand" to="/"><img src="../assets/logo1.png" style="height: 50px;width:120px"></router-link>
      <button class="navbar-toggler" style="border:none" type="button" @click="toggleOverlay"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars" style="color:orange"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="position: absolute;right: 0;">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/detailsBien" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >Simuler un prêt</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/faq" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >FAQ</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="overlay container-fluid" :class="{ active: isOverlay1Active }" style='overflow-y:auto;width: 100vw;' >
    <div class="container mt-5" style="display:flex;justify-content:end">
      <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay1"><i class="fa fa-close"></i></button>
    </div>
    <router-link style="font-size: 32px;color: orange;font-family:'Caveat'" class="navbar-brand" to="/">Créfit</router-link>
    <div class="container" style='overflow-y:auto;text-align:start'>
    <hr style="margin-top: 10px; color: #DCDCDC;">
    <h3 style="font-size:30px;text-decoration: underline;">Conditions Générales de Prêt aux Étudiants</h3>
    <p>Bienvenue sur notre plateforme de prêt dédiée aux étudiants. Veuillez lire attentivement les conditions générales suivantes avant de soumettre votre demande de prêt en ligne. Ces conditions s'appliquent à tous les emprunteurs.</p>
    <h4 style="font-weight: bold;font-size:25px">1. Admissibilité au Prêt</h4>
    <div>Le prêt est ouvert aux étudiants boursiers et non boursiers selon les critères suivants :</div>
        <div><p style="color:orange">Étudiants Boursiers :</p>
            <div>
                <div>Fournir une attestation de bourse délivrée par l'AMCI.</div>
                <div>Fournir une copie de votre passeport.</div>
                </div>
              </div>
        <div><p style="color:orange">Étudiants Non Boursiers :</p>
            <div>
                <div>Fournir une copie de votre passeport.</div>
                <div>Déposer un objet de valeur (ordinateur portable, téléviseur, téléphone) en guise de garantie.</div>
            </div>
        </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">2. Procédure de Demande de Prêt</h4>
        <div><p style="color:orange">Remplir le Formulaire en Ligne :</p>
            <div>
                <div>Complétez le formulaire de demande de prêt disponible sur notre site web avec toutes les informations requises.</div>
            </div>
        </div>
        <div><p style="color:orange">Prise de Contact :</p>
            <div>
                <div>Après soumission de votre formulaire, notre équipe vous contactera par WhatsApp pour organiser un rendez-vous.</div>
            </div>
        </div>
        <div><p style="color:orange">Rendez-vous pour Dépôt des Documents :</p>
            <div>
                <div>Lors du rendez-vous, vous devrez présenter les documents requis :
                    <div>
                        <div>Pour les boursiers : attestation de bourse et passeport.</div>
                        <div>Pour les non boursiers : passeport et objet de valeur.</div>
                    </div>
                </div>
                <div>Nos agents vérifieront les documents et/ou les objets déposés.</div>
            </div>
        </div>
        <div><p style="color:orange">Virement Bancaire :</p>
            <div>
                <div>Une fois les documents et/ou objets vérifiés, nous effectuerons un virement bancaire sur votre compte CIH dans les plus brefs délais.</div>
            </div>
        </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">3. Modalités de Remboursement</h4>
    <div>
        <div><p style="color:orange">Date de Remboursement :</p>
            <div>
                <div>La date de remboursement est celle que vous aurez sélectionnée lors de votre demande en ligne.</div>
            </div>
        </div>
        <div><p style="color:orange">Procédure de Remboursement :</p>
            <div>
                <div>Effectuez un virement bancaire du montant du prêt sur notre compte.</div>
                <div>Si le remboursement est effectué avant la date de remboursement prévue, le montant des intérêts à payer sera réduit.</div>
                <div>Après réception et confirmation du paiement, nous organiserons un rendez-vous pour vous restituer votre passeport et/ou objet de valeur.</div>
            </div>
        </div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">4. Pénalités de Retard</h4>
    <div>
        <div>Si vous dépassez la date de remboursement, des pénalités de retard seront appliquées. Le montant des pénalités sera calculé en fonction du nombre de jours de retard.</div>
        <div>Les détails des pénalités seront affichés lors de la simulation de prêt sur notre site web.</div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">5. Simulation de Prêt</h4>
    <div>
        <div>Avant de soumettre votre demande, utilisez notre outil de simulation de prêt pour estimer le montant total à rembourser, y compris les éventuelles pénalités en cas de retard.</div>
        <div>La simulation vous permettra de mieux comprendre les conditions financières de votre prêt.</div>
    </div>
    <br>
    <h4 style="font-weight: bold;font-size:25px">6. Assistance et Contact</h4>
    <div>
        <div>Pour toute question ou assistance supplémentaire, veuillez nous contacter par email à crefitfacile@gmail.com ou par téléphone au +212 0632033353.</div>
    </div>

    <p>En soumettant votre demande de prêt, vous acceptez les présentes conditions générales. Nous nous engageons à traiter vos informations avec la plus grande confidentialité et à vous offrir un service de qualité.</p>
    <hr>
       
    </div>
    </div>

  <div class="overlay" :class="{ active: isOverlayActive }" style="z-index:9999999">
    <div class="container mt-5" style="display:flex;justify-content:end">
      <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay"><i class="fa fa-close"></i></button>
    </div>
    <router-link style="font-size: 32px;color: orange;font-family:'Caveat'" class="navbar-brand" to="/">Créfit</router-link>
    <div class="content">
      <div class="button-container">
        <router-link class="nav-link" @click="closeOverlay" to="/detailsBien" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white">Simuler un prêt </router-link>
    </div>
    <br>
    <div class="button-container">
      <router-link class="nav-link" @click="closeOverlay" to="/faq" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white" >FAQ</router-link>
    </div>
    <hr style="margin-top: 50px; color: #DCDCDC;">
    <div class="content">
      Nous comprenons les défis uniques auxquels vous faites face et sommes ici pour vous offrir une solution financière adaptée à vos besoins
    </div>
    </div>
</div>
    <div class="container" style="margin-bottom: 20vh;margin-top: 10vh;">
      <h1 class="text-center mb-4" style='color:orange'>FAQ</h1>
      <div class="accordion" id="faqAccordion">
        <div v-for="(question, index) in faqList" :key="index" class="accordion-item">
          <h2 class="accordion-header" id="heading{{ index }}">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
              {{ question.title }}
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ 'show': index === 0 }" aria-labelledby="heading{{ index }}" data-bs-parent="#faqAccordion">
            <div class="accordion-body">
              {{ question.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
<section class='container-fluid' style='width:100vw;background-color:black'>
  <footer class="footer-fluid" style="width: 100vw;background-color: black;margin-left: -12px;padding-top: 20px;color: white;padding-left: 10px;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" style='text-align:start'>
        <span style='color:orange;font-size:30px;font-weight:bold'>Créfit</span><br>
        Notre plateforme offre des solutions de prêt adaptées à vos besoins spécifiques, avec une limite de 800 dirhams pour les étudiants et de 1000 dirhams pour les non-étudiants. Nous comprenons que chaque situation est unique, c'est pourquoi les conditions de prêt peuvent varier d'une personne à l'autre, garantissant une flexibilité qui répond à vos circonstances individuelles. De plus, le taux d'intérêt sur les prêts est ajusté en fonction de chaque situation, vous assurant des conditions transparentes et équitables. Profitez d'un service personnalisé qui s'adapte à vos besoins pour vous soutenir dans vos projets financiers.
        <button  type="button" class="detail btn btn b-prof" @click="toggleOverlay1" style="color:orange;">Nous vous conseillons de voir les conditions.</button>
        <p>Vous pouvez simuler un prêt en cliquant <router-link class="" style='color:orange' to="/detailsBien">ici </router-link></p>
      </div>
      <div class="">
        <div class="contact-item">
          <div class="" style="padding-right: 30px;font-size:8px">
            <i class="fas fa-phone-alt"></i>
            <span style="font-size: 10px;">+212 0632033353</span>
          </div>
          <div>
            <i class="fas fa-envelope"></i>
            <span style="font-size: 10px;">crefitfacile@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</footer>

</section>

  </template>
  
  <script>
  export default {
    data() {
      return {
        faqList: [
          {
            title: "Qui peut bénéficier d'un prêt sur votre plateforme ?",
            content: "Notre service de prêt est ouvert à tous les étudiants, qu'ils soient boursiers ou non boursiers. Les étudiants boursiers doivent fournir une attestation de bourse délivrée par l'AMCI et une copie de leur passeport. Les étudiants non boursiers doivent fournir une copie de leur passeport et déposer un objet de valeur (ordinateur portable, téléviseur, téléphone) en guise de garantie."
          },
          {
            title: "Comment puis-je faire une demande de prêt ?",
            content: "Pour faire une demande de prêt, remplissez le formulaire en ligne disponible sur notre site web. Une fois le formulaire soumis, notre équipe vous contactera par WhatsApp pour organiser un rendez-vous où vous devrez présenter les documents requis (attestation de bourse et passeport pour les boursiers ; passeport et objet de valeur pour les non boursiers)."
          },
          {
            title: "Quels sont les délais pour recevoir les fonds après la validation de ma demande ?",
            content: "Une fois les documents et/ou objets déposés lors de votre rendez-vous, nous effectuerons un virement bancaire instantané sur votre compte CIH. La mise en place du rendez-vous se fait dans un délai maximum de 24 heures après la soumission de votre formulaire."
          },
          {
            title: "Comment puis-je rembourser mon prêt ?",
            content: "Le remboursement doit être effectué avant ou à la date de remboursement que vous aurez sélectionnée lors de votre demande en ligne. Vous devez effectuer un virement bancaire du montant du prêt sur notre compte et envoyer la capture d'écran de la confirmation du virement au numéro WhatsApp qui vous a contacté. Une fois le paiement confirmé, nous organiserons un rendez-vous pour vous restituer votre passeport et/ou objet de valeur."
          },
          {
            title: "Que se passe-t-il si je ne rembourse pas mon prêt à temps ?",
            content: "Si vous dépassez la date de remboursement, des pénalités de retard seront appliquées. Le montant des pénalités est calculé en fonction du nombre de jours de retard. Vous pourrez consulter les détails des pénalités lors de la simulation de prêt sur notre site web. Pour éviter des frais supplémentaires, assurez-vous de respecter la date de remboursement convenue."
          },
          {
            title: "Où se déroulera le rendez-vous pour déposer les documents ou objets ?",
            content: "Le lieu du rendez-vous sera précisé lors de la discussion sur WhatsApp avec notre équipe. Nous nous efforçons de choisir un lieu pratique et sûr pour nos clients."
          }
          // Ajoutez d'autres questions et réponses selon vos besoins
        ],
        isOverlayActive: false,
        isOverlay1Active:false
      };
    },
    methods: {
      toggleOverlay() {
      this.isOverlayActive = !this.isOverlayActive;
    },
    closeOverlay() {
      this.isOverlayActive = false;
    },
    toggleOverlay1() {
      this.isOverlay1Active = !this.isOverlay1Active;
    },
    closeOverlay1() {
      this.isOverlay1Active = false;
    },
    tofaq(){
      this.$router.push({name:'faq'})
    },
    }
  };
  </script>
  
  <style scoped>

    .btn-primary{
    background-color: orange;
    color: white;
    border: none;
  }
  .btn-primary:hover{
    scale: 1.1;
    transition:0.3s;
    background-color: orange;
  }

.overlay::-webkit-scrollbar {
  width: 0px; /* Masquer la barre de défilement verticale */
  height: 0px; /* Masquer la barre de défilement horizontale */
}
.overlay {
  position: fixed;
  top: 0;
  left: -102%; /* Position initiale en dehors de l'écran */
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: left 0.5s ease; /* Transition de la propriété 'left' */
}

.overlay.active {
  left: 0; /* Position de la gauche de l'écran */
}

.overlay .content {
  padding: 20px;
}

  .valeur{
    text-align: end;
    font-weight: bold;
  }
  .accroche{
    text-align: start;
    font-size: 40px;
    font-weight: bold;
    width: 70%;
  }
  .petit_titre{
    text-align: start;
    font-weight: bold;
    font-size: 20px;
    margin-top: 30px;
  }
  .info-entoure{
    border-radius: 40px;
    border: 1px solid #d9d9d9;
    text-align: start;
    width: fit-content;
    padding: 10px;
    margin-bottom: 20px;
  }
  .fixed {
  position: sticky;
  z-index: -12;
  top: 150px; /* Ajustez la valeur en fonction de votre mise en page */
}
.card-image {
      position: relative; /* Position relative pour le positionnement absolu du texte */
      overflow: hidden;
      border-radius: 30px;
    }
  .card-image img {
    width: 400px; /* Utilise toute la largeur disponible */
    height: 300px; /* Ajuste automatiquement la hauteur en fonction de la largeur */
    object-fit: cover;
    border-radius: 30px;
    transition: transform 0.3s;
  }
  .card-image:hover img {
      transform: scale(1.1); /* Zoom sur l'image lorsqu'elle est survolée */
    }
    .card-title {
    position: absolute; /* Position absolue pour superposer le texte sur l'image */
    bottom: 25px; /* Marge inférieure par rapport au bas de l'image */
    left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
    color: white; /* Couleur du texte */
    font-size: 24px; /* Taille de la police */
    font-weight: bold; /* Gras */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
  }
  .card-subtitle {
    position: absolute; /* Position absolue pour superposer le texte sur l'image */
    bottom: 15px; /* Marge inférieure par rapport au bas de l'image */
    left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
    color: white; /* Couleur du texte */
    font-size: 24px; /* Taille de la police */
    font-weight: bold; /* Gras */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
  }

  .nav-link{
  color: rgb(0, 0, 0);
}
.navbar-brand{
  color: rgb(0, 0, 0);
  font-size: 32px;
}
.navbar-brand:hover{
  color: orange;
  font-size: 32px;
}
.nav-link{
  font-weight: bold;
}
.nav-link:hover{
  background-color: white;

}
.titre{
  padding-top: 50px;
  font-size: 35px;
  color: rgb(0, 0, 0);
  font-family: monospace;
}
nav.scrolled {
    background-color: rgba(255, 255, 255, 0.9); /* Fond de la barre de navigation après le défilement */
}
.footer h5 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.footer p {
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer ul li a:hover {
  text-decoration: underline;
}
.footer {
  background-color: #333;
  color: #fff;
  padding: 30px 0;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
}

  </style>
  