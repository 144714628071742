<template>
  <router-view/>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Navbar',
  data() {
    return {
      isOverlayActive: false,
      isOverlay1Active: false,
    };
  },
  mounted() {
    // Rendre jQuery disponible globalement
    window.$ = window.jQuery = $
  },
  methods: {
    toggleOverlay() {
      this.isOverlayActive = !this.isOverlayActive;
    },
    closeOverlay() {
      this.isOverlayActive = false;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Kanit');
@import url('https://fonts.googleapis.com/css?family=Teachers');
@import url('https://fonts.googleapis.com/css?family=Caveat');
@import url('https://fonts.googleapis.com/css?family=Gruppo');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family:'Kanit';
  background-color: #f5f5f5;
  
}
html, body {
    background-color: #f5f5f5; /* Remplacez par la couleur de fond souhaitée */
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
.nav-link {
  text-decoration: none;
  font-weight: bold;
  border-radius: 20px;
}


.nav-link:hover{
  background-color: white
}
.nav-link-li{
  background-color: #cfcfcf;
  padding: 7px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
  color: white;
}


.titre{
  margin-top: 80px;
  font-size: 65px;
  font-weight: bold;
  margin-bottom: 70px;
}

.navbar-brand {
    margin: 0;
    color: white;
    font-size: 32px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

li {
    margin-right: 20px;
}

a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}
.overlay {
  position: fixed;
  top: 0;
  left: -100%; /* Position initiale en dehors de l'écran */
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: left 0.5s ease; /* Transition de la propriété 'left' */
}

.overlay.active {
  left: 0; /* Position de la gauche de l'écran */
}

.overlay .content {
  padding: 20px;
}

.toggler {
  background: none;
  border: none;
  cursor: pointer;
}
.close-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

</style>
