<template >
  <nav class="navbar navbar-expand-lg navbar-light shadow-sm" style="z-index: 3;background-color:white;">
    <div class="container-fluid">
      <router-link style="margin-top: -6px;" class="navbar-brand" to="/"><img src="../assets/logo1.png" style="height: 50px;width:120px"></router-link>
      <button class="navbar-toggler" style="border:none" type="button" @click="toggleOverlay"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars" style="color:orange"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="position: absolute;right: 0;">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/detailsBien" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >Simuler un prêt </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/faq" style="color: white;font-weight: bold;background-color:orange;border-radius:20px;padding:2px 10px 2px 10px" >FAQ</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
<ol style='padding:0;margin:0'>
  <div class="overlay container-fluid" :class="{ active: isOverlay1Active }" style='overflow-y:auto;width: 100vw;' reader-view="none">
    <div class="container mt-5" style="display:flex;justify-content:end">
      <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay1"><i class="fa fa-close"></i></button>
    </div>
    <router-link style="font-size: 32px;color: orange;font-family:'Caveat'" class="navbar-brand" to="/">Créfit</router-link>
    <div class="container" style='overflow-y:auto;text-align:start'>
    <hr style="margin-top: 10px; color: #DCDCDC;">
    <h1 style="font-size:30px;text-decoration: underline;">Conditions Générales de Prêt aux Étudiants</h1>
    <div>Bienvenue sur notre plateforme de prêt dédiée aux étudiants. Veuillez lire attentivement les conditions générales suivantes avant de soumettre votre demande de prêt en ligne. Ces conditions s'appliquent à tous les emprunteurs.</div>
    <div style="font-weight: bold;font-size:25px">1. Admissibilité au Prêt</div>
    <div>Le prêt est ouvert aux étudiants boursiers et non boursiers selon les critères suivants :</div>
        <div><div style="color:orange">Étudiants Boursiers :</div>
            <div>
                <div>Fournir une attestation de bourse délivrée par l'AMCI.</div>
                <div>Fournir une copie de votre passeport.</div>
                </div>
              </div>
        <div><div style="color:orange">Étudiants Non Boursiers :</div>
            <div>
                <div>Fournir une copie de votre passeport.</div>
                <div>Déposer un objet de valeur (ordinateur portable, téléviseur, téléphone) en guise de garantie.</div>
            </div>
        </div>
    <br>
    <div style="font-weight: bold;font-size:25px">2. Procédure de Demande de Prêt</div>
        <div><div style="color:orange">Remplir le Formulaire en Ligne :</div>
            <div>
                <div>Complétez le formulaire de demande de prêt disponible sur notre site web avec toutes les informations requises.</div>
            </div>
        </div>
        <div><div style="color:orange">Prise de Contact :</div>
            <div>
                <div>Après soumission de votre formulaire, notre équipe vous contactera par WhatsApp pour organiser un rendez-vous.</div>
            </div>
        </div>
        <div><div style="color:orange">Rendez-vous pour Dépôt des Documents :</div>
            <div>
                <div>Lors du rendez-vous, vous devrez présenter les documents requis :
                    <div>
                        <div>Pour les boursiers : attestation de bourse et passeport.</div>
                        <div>Pour les non boursiers : passeport et objet de valeur.</div>
                    </div>
                </div>
                <div>Nos agents vérifieront les documents et/ou les objets déposés.</div>
            </div>
        </div>
        <div><div style="color:orange">Virement Bancaire :</div>
            <div>
                <div>Une fois les documents et/ou objets vérifiés, nous effectuerons un virement bancaire sur votre compte CIH dans les plus brefs délais.</div>
            </div>
        </div>
    <br>
    <div style="font-weight: bold;font-size:25px">3. Modalités de Remboursement</div>
    <div>
        <div><div style="color:orange">Date de Remboursement :</div>
            <div>
                <div>La date de remboursement est celle que vous aurez sélectionnée lors de votre demande en ligne.</div>
            </div>
        </div>
        <div><div style="color:orange">Procédure de Remboursement :</div>
            <div>
                <div>Effectuez un virement bancaire du montant du prêt sur notre compte.</div>
                <div>Si le remboursement est effectué avant la date de remboursement prévue, le montant des intérêts à payer sera réduit.</div>
                <div>Après réception et confirmation du paiement, nous organiserons un rendez-vous pour vous restituer votre passeport et/ou objet de valeur.</div>
            </div>
        </div>
    </div>
    <br>
    <div style="font-weight: bold;font-size:25px">4. Pénalités de Retard</div>
    <div>
        <div>Si vous dépassez la date de remboursement, des pénalités de retard seront appliquées. Le montant des pénalités sera calculé en fonction du nombre de jours de retard.</div>
        <div>Les détails des pénalités seront affichés lors de la simulation de prêt sur notre site web.</div>
    </div>
    <br>
    <div style="font-weight: bold;font-size:25px">5. Simulation de Prêt</div>
    <div>
        <div>Avant de soumettre votre demande, utilisez notre outil de simulation de prêt pour estimer le montant total à rembourser, y compris les éventuelles pénalités en cas de retard.</div>
        <div>La simulation vous permettra de mieux comprendre les conditions financières de votre prêt.</div>
    </div>
    <br>
    <div style="font-weight: bold;font-size:25px">6. Assistance et Contact</div>
    <div>
        <div>Pour toute question ou assistance supplémentaire, veuillez nous contacter par email à crefitfacile@gmail.com ou par téléphone au +212 0632033353.</div>
    </div>

    <p>En soumettant votre demande de prêt, vous acceptez les présentes conditions générales. Nous nous engageons à traiter vos informations avec la plus grande confidentialité et à vous offrir un service de qualité.</p>
    <hr>
    </div>


    <div class="navbar-nav ml-auto content " style="text-align: start;margin-left: 10px;font-weight: bold;">
          <div class="nav-item container">
            <router-link class="nav-link" @click="closeOverlay" to="/detailsBien" style="border-radius: 20px;background-color: orange;padding: 7px;color:white;width:fit-content;">Simuler un prêt </router-link>
          </div>
        </div>
    </div>
  <div class="overlay" :class="{ active: isOverlayActive }" style="z-index:9999999">
    <div class="container mt-5" style="display:flex;justify-content:end">
      <button class="btn btn mt-3" style="border-radius: 50px;background-color: orange;color:white;" @click="closeOverlay"><i class="fa fa-close"></i></button>
    </div>
    <router-link style="font-size: 32px;color: orange;font-family: Caveat;" class="navbar-brand" to="/">Créfit</router-link>
    <div class="content">
      <div class="button-container">
        <router-link class="nav-link" @click="closeOverlay" to="/detailsBien" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white">Simuler un prêt </router-link>
    </div>
    <br>
    <div class="button-container">
      <router-link class="nav-link" @click="closeOverlay" to="/faq" style="border-radius: 20px;padding-left: 35%;background-color: orange;padding: 7px;color:white" >FAQ</router-link>
    </div>
    
    <hr style="margin-top: 50px; color: #DCDCDC;">
    <div class="content">
      Nous comprenons les défis uniques auxquels vous faites face et sommes ici pour vous offrir une solution financière adaptée à vos besoins
    </div>
    </div>
</div>
  <router-link class="container shadow-lg" @click="closeOverlay" to="/detailsBien" style="border-radius: 20px;background-color: orange;padding: 7px;color:white;position:fixed;bottom:5vh;left: 0;width: fit-content;margin-left: 20px;text-decoration: none;z-index:1000000">Simuler un prêt</router-link>
  <div class="nab-bg" style="z-index: 2;">
    <h1 class="titre">Le <span class="titre-color" style="color: orange;">crédit instantané </span><br>près de chez vous partout au <span class="titre-color" style="color: orange;"> Maroc</span></h1>
    <div class="container-fluid">
      <div class="shadow-sm carte-sm" style="border:none;width: 100%;margin-top: -30px;background-color: white;padding: 0px 10px 0px 10px;border-radius: 5px;">
          <div class="row">
            <div class="col-md-5 mdr" style="border-radius: 5px 5px 0 0px;">
              <p class="container "  :style="{ color: titreColor, fontSize: '30px', textAlign: 'center', backgroundColor: 'rgba(0, 60, 255, 0.2)', borderRadius: '20px', width: 'fit-content',display:'none' }">Nous sommes là pour vous aider !</p>
            </div>
            <div class="col-md-7">
              <p class="mt-3" style="color:#003883;font-size:20px;text-align: center;background-color:rgba(0, 60, 255, 0.2);border-radius: 20px;width:fit-content;padding: 0 10px 0 10px;">Nous sommes là pour vous aider !</p>
              <p style="font-weight: light;width: 100%;text-align: justify;margin-top: -7px;" class="text-muted">
                Bienvenue sur notre plateforme de prêt de proximité dédiée aux étrangers résidant au Maroc ! Nous comprenons vos défis uniques et vous proposons une solution financière adaptée. Pour démarrer un projet, gérer une urgence ou améliorer votre quotidien, notre service offre des prêts rapides, transparents et accessibles.
                  </p>
                  <div class='row d-flex justify-content-center'>
                    <router-link class="btn btn b-prof mb-2" style="background-color: orange;color: white;border-radius: 30px;text-align: center;width:fit-content;margin-right: 5px;" to="/detailsBien">Simuler un prêt </router-link>
                    <button  type="button" class="detail btn btn b-prof mb-2" @click="toggleOverlay1" style="background-color:black;color: white;border-radius: 30px;text-align: center;width:fit-content">Voir conditions<i class="fa fa-arrow-right" style="color: white;padding: 1px;font-size: 10px;"></i> </button>
                  </div>
            </div>
          </div>
      </div>
    <div class="row carte" style="margin-top: -20px;">
      <div class="col-md-11 container-fluid" >
        <div class="card container-fluid shadow-sm" style="background-color: white;border:none;z-index: 99999999999;">
          <div class="card-body" style="width: 100%;" >
            <h1 class="mt-4" style="font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;font-size: 40px;color: orange;"></h1>
          </div>
          <div class="row mb-3 " style="height: 350px;">
            <div class="col-md-8" style="padding: 10px 20px 20px 55px;">
              <div class="row mt-2 shadow-sm " style="background-color: whitesmoke;border-radius: 2px;">
                <div class="col-md-4 mdr" style="background-position:center;">
                </div>
                <div class="col-md-8 mt-4">
                  <p class="mt-3" style="color:#003883;font-size:20px;text-align: center;background-color:rgba(0, 60, 255, 0.2);border-radius: 20px;width:fit-content;padding: 0 10px 0 10px;">Nous sommes là pour vous aider !</p>
                  <p style="color:black;width: 90%;text-align: start;text-align: start;font-weight:light" class="text-muted">Bienvenue sur notre plateforme de prêt de proximité dédiée aux étrangers résidant au Maroc ! Nous comprenons les défis uniques auxquels vous faites face et sommes ici pour vous offrir une solution financière adaptée à vos besoins. Que ce soit pour démarrer un projet, faire face à une urgence ou simplement améliorer votre quotidien, notre service vous propose des prêts rapides, transparents et accessibles. 
                  </p>
                  <div class="row" style="margin-left: 10px;">
                    <router-link class="btn btn b-prof mb-2" style="background-color: orange;color: white;border-radius: 30px;text-align: center;width:fit-content;margin-right: 5px;" to="/detailsBien">Simuler un prêt </router-link>
                  <button  type="button" class="detail btn btn b-prof mb-2" @click="toggleOverlay1" style="background-color:black;color: white;border-radius: 30px;text-align: center;width:fit-content">Voir conditions <i class="fa fa-arrow-right" style="color: white;padding: 1px;font-size: 20px;"></i> </button>
                  </div>
                </div>
              </div>
              <br>
            </div>
            <div class="col-md-4 mt-5 p-3" style="text-align: start;">
                <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i><span style="font-weight: bold;">Procédure Simplifiée et Rapide</span></div>
                <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i><span style="font-weight: bold;">Taux d'Intérêt Compétitifs et Transparence Totale</span></div>
                <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i><span style="font-weight: bold;">Accompagnement Personnalisé et Service Multilingue </span></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

    <!-- <div style="position: absolute;bottom: 15vh;left: 13vw;"">
    </div> -->
  </div>
  <div class="container-fluid" style="position:absolute; top: 97vh;z-index: 1;justify-content: center;background-color:white">
    <div class="container-fluid mt-5 carte-sm" style="width: 97vw;border-radius: 35px;padding: 10px;">
      <div class="col-md-7 mt-5 p-3" style="text-align: start;">
                <!-- <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i><span style="font-weight: bold;">Pas de frais</span> de tenue de compte</div><br>
                <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i><span style="font-weight: bold;">Jusqu'à 350$ d'économies </span> sur vos frais bancaires par an</div><br>
                <div><i class="fa fa-check" style="color: orange;padding: 10px;font-size: 30px;"></i>Banque la <span style="font-weight: bold;">moins chère</span>  depuis 16 ans</div><br> -->
            </div>
    </div>

    <div class="row mt-5 container-fluid" style="width: 99%;justify-content: center;display: flex;padding-right: 0px;">
      <div style='font-size:30px;margin-top: 3px;margin-bottom:50px'>Déjà plus de 130 crédits accordés....</div>
      <div class="col-md-7 container-fluid " style="padding-right: 0px;">
        <h1 style="text-align: center;font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
          Un crédit chez <span style='color:orange'>Créfit</span>, comment ça se passe ?
        </h1>
        <br>
        <div class='row'>
          <div class='col-md-4 mb-3' >
            <div class='card shadow-lg' style='border:none;min-height:350px'>
              <div class='row d-flex justify-content-center'>
                <img src="../assets/rocket-flame.svg" style="object-fit: contain;height:40%;width:40%">
              </div>
              <div class='row'>
                <div>
                  <h4>Réponse<br> <span style='font-weight:bold'> définitive immédiate</span></h4>
                  <br>
                  <p class='container-fluid' style='width:80%'>
                    Notre technologie offre un service de crédit rapide : réponse définitive immédiate et mise à disposition des fonds express.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class='col-md-4 mb-3' >
            <div class='card shadow-lg' style='border:none;min-height:350px'>
              <div class='row d-flex justify-content-center'>
                <img src="../assets/hand-easy-fast.svg" style="object-fit: contain;height:40%;width:40%">
              </div>
              <div class='row'>
                <div>
                  <h4>Demande de<br> <span style='font-weight:bold'>crédit 100% en ligne</span></h4>
                  <br>
                  <p class='container-fluid' style='width:80%'>
                    Simulez votre crédit pour voir les conditions de prêt.Ensuite vous pouvez demander votre prêt si les conditions vous conviennent
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div class='col-md-4 mb-3' >
            <div class='card shadow-lg' style='border:none;min-height:350px'>
              <div class='row d-flex justify-content-center'>
                <img src="../assets/security-protection.svg" style="object-fit: contain;height:40%;width:40%">
              </div>
              <div class='row'>
                <div>
                  <h4><span style='font-weight:bold'>Sécurité et protection</span><br>de vos données</h4>
                  <br>
                  <p class='container-fluid' style='width:80%'>
                    Notre plateforme est une plateforme est activement engagée dans le respect des normes de sécurité et protège vos données.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 container-fluid" style="width: 97%;justify-content: center;display: flex;">
      <div class="col-md-5 align-content-center">
        <h1 style="text-align: start;font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
          Pourquoi choisir <span style='color:orange'>Créfit</span> en tant qu'<span style='color:orange'>étudiant</span>
        </h1>
        <p style="text-align: start;">
          Poursuivre vos études au Maroc présente des défis financiers uniques. Notre plateforme propose des prêts adaptés avec des conditions flexibles et des taux compétitifs. Profitez d'une procédure rapide pour obtenir des fonds rapidement, couvrant vos frais de scolarité, matériel académique et dépenses quotidiennes.
        </p>
      </div>
      <div class="col-md-5">
        <img src="../assets/etudiant.png" height="90%" width="90%" style="object-fit: contain;">
      </div>
    </div>
    <div class="card row mt-5 container-fluid shadow-sm d-flex justify-content-center border-none p-2" style="background-color:#FEF9F8;margin-left:4px;border:none">
      <h1 style="font-weight: bold;color: orange;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">Vous n’êtes pas étudiant ?</h1>
      <p>Pas de panique ! Notre plateforme est également conçue pour répondre aux besoins financiers des résidents étrangers non étudiants au Maroc. Que vous ayez besoin de fonds pour lancer un projet, gérer une urgence, ou améliorer votre quotidien, nous avons des solutions adaptées pour vous. Avec des taux d'intérêt compétitifs, une transparence totale et une procédure de demande rapide et simplifiée, accéder à un prêt n'a jamais été aussi facile</p>
    </div>
    <br>
    <div class="row mt-2 container-fluid" style="width: 97%;justify-content: center;display: flex;">
      <div class="col-md-5 align-content-center">
        <h1 style="text-align: start;font-weight: bold;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
          Obtenez un <span style='color:orange'>CODE PROMO</span> dès votre premier <span style='color:orange'>prêt </span>effectué
        </h1>
        <p style="text-align: start;">
          Lorsque vous effectuez votre tout premier prêt avec nous, vous recevez un code promotionnel vous donnant droit à des réductions pouvant aller jusqu'à 12% sur les intérêts de vos prêts.
        </p>
        <div class="d-flex justify-content-start">
          <a :href="pdfpath" download="promo.pdf" class="btn btn-primary" style="border:none;border-radius:20px">
            En savoir plus
          </a>
        </div>
      </div>
      <div class="col-md-5">
        <img src="../assets/CODEPROMO.png" height="90%" width="90%" style="object-fit: contain;">
      </div>
    </div>
    <hr>
    <div class="row mt-5" style="width: 97vw;">
      <div class="mt-4 " style="padding-top: 5px;">
        <h1 style="text-align: center;width: 97%;color: orange;font-weight: bold;">Rejoignez-nous facilement!</h1>
        <p class="container" style="text-align: center;width: 80%;background-color: orange;color:white;font-weight: bold;padding: 20px;border-radius: 10px;">
          Nous comprenons les défis uniques auxquels vous faites face et sommes ici pour vous offrir une solution financière adaptée à vos besoins.
        </p>
      <router-link class="btn btn b-prof mb-4" style="background-color: orange;color: white;border-radius: 30px;" to="/detailsBien">Simuler un prêt </router-link>
      </div>
    </div>
    <hr>
    <div class="container mt-1">
      <h1 class="text-center mb-4" style='color:orange;font-weight: bold;'>FAQ</h1>
      <div class="accordion" id="faqAccordion">
        <div v-for="(question, index) in faqList" :key="index" class="accordion-item">
          <h2 class="accordion-header" id="heading{{ index }}">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
              {{ question.title }}
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ 'show': index === 0 }" aria-labelledby="heading{{ index }}" data-bs-parent="#faqAccordion">
            <div class="accordion-body">
              {{ question.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <footer class="footer-fluid" style="width: 100vw;background-color: black;margin-left: -12px;padding-top: 20px;color: white;padding-left: 10px;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" style='text-align:start'>
        <span style='color:orange;font-size:30px;font-weight:bold'>Créfit</span><br>
        Notre plateforme offre des solutions de prêt adaptées à vos besoins spécifiques, avec une limite de 800 dirhams pour les étudiants et de 1000 dirhams pour les non-étudiants. Nous comprenons que chaque situation est unique, c'est pourquoi les conditions de prêt peuvent varier d'une personne à l'autre, garantissant une flexibilité qui répond à vos circonstances individuelles. De plus, le taux d'intérêt sur les prêts est ajusté en fonction de chaque situation, vous assurant des conditions transparentes et équitables. Profitez d'un service personnalisé qui s'adapte à vos besoins pour vous soutenir dans vos projets financiers.
        <button  type="button" class="detail btn btn b-prof" @click="toggleOverlay1" style="color:orange;">Nous vous conseillons de voir les conditions.</button>
        <p>Vous pouvez simuler un prêt en cliquant <router-link class="" style='color:orange' to="/detailsBien">ici </router-link></p>
      </div>
      <div class="">
        <div class="contact-item">
          <div class="" style="padding-right: 30px;font-size:8px">
            <i class="fas fa-phone-alt"></i>
            <span style="font-size: 10px;">+212 0632033353</span>
          </div>
          <div>
            <i class="fas fa-envelope"></i>
            <span style="font-size: 10px;">crefitfacile@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</footer>
    
  </div>
</ol>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import axios from 'axios'
import HelloWorld from '@/components/HelloWorld.vue';
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      pdfpath:'/promo.pdf',
      isOverlay1Active: false,
      isOverlayActive: false,
      titreColor: 'orange', // Couleur de départ
      colors: ['orange', '#ff7f0e'], // Bleu et Orange
      currentIndex: 0,
      faqList: [
          {
            title: "Qui peut bénéficier d'un prêt sur votre plateforme ?",
            content: "Notre service de prêt est ouvert à tous les étudiants, qu'ils soient boursiers ou non boursiers. Les étudiants boursiers doivent fournir une attestation de bourse délivrée par l'AMCI et une copie de leur passeport. Les étudiants non boursiers doivent fournir une copie de leur passeport et déposer un objet de valeur (ordinateur portable, téléviseur, téléphone) en guise de garantie."
          },
          {
            title: "Comment puis-je faire une demande de prêt ?",
            content: "Pour faire une demande de prêt, remplissez le formulaire en ligne disponible sur notre site web. Une fois le formulaire soumis, notre équipe vous contactera par WhatsApp pour organiser un rendez-vous où vous devrez présenter les documents requis (attestation de bourse et passeport pour les boursiers ; passeport et objet de valeur pour les non boursiers)."
          },
          {
            title: "Quels sont les délais pour recevoir les fonds après la validation de ma demande ?",
            content: "Une fois les documents et/ou objets déposés lors de votre rendez-vous, nous effectuerons un virement bancaire instantané sur votre compte CIH. La mise en place du rendez-vous se fait dans un délai maximum de 24 heures après la soumission de votre formulaire."
          },
          {
            title: "Comment puis-je rembourser mon prêt ?",
            content: "Le remboursement doit être effectué avant ou à la date de remboursement que vous aurez sélectionnée lors de votre demande en ligne. Vous devez effectuer un virement bancaire du montant du prêt sur notre compte et envoyer la capture d'écran de la confirmation du virement au numéro WhatsApp qui vous a contacté. Une fois le paiement confirmé, nous organiserons un rendez-vous pour vous restituer votre passeport et/ou objet de valeur."
          },
          {
            title: "Que se passe-t-il si je ne rembourse pas mon prêt à temps ?",
            content: "Si vous dépassez la date de remboursement, des pénalités de retard seront appliquées. Le montant des pénalités est calculé en fonction du nombre de jours de retard. Vous pourrez consulter les détails des pénalités lors de la simulation de prêt sur notre site web. Pour éviter des frais supplémentaires, assurez-vous de respecter la date de remboursement convenue."
          },
          {
            title: "Où se déroulera le rendez-vous pour déposer les documents ou objets ?",
            content: "Le lieu du rendez-vous sera précisé lors de la discussion sur WhatsApp avec notre équipe. Nous nous efforçons de choisir un lieu pratique et sûr pour nos clients."
          }
          // Ajoutez d'autres questions et réponses selon vos besoins
        ]
    };
  },
  mounted() {
    // Rendre jQuery disponible globalement
    window.$ = window.jQuery = $
    setInterval(this.changeColor, 3000);
  },
  methods: {
    toggleOverlay1() {
      this.isOverlay1Active = !this.isOverlay1Active;
    },
    closeOverlay1() {
      this.isOverlay1Active = false;
    },
    tofaq(){
      this.$router.push({name:'faq'})
    },
    toggleOverlay() {
      this.isOverlayActive = !this.isOverlayActive;
    },
    closeOverlay() {
      this.isOverlayActive = false;
    },
    details(){
      this.$router.push('/detailsBien');
    },
    changeColor() {
            this.currentIndex = (this.currentIndex + 1) % this.colors.length;
            this.titreColor = this.colors[this.currentIndex];
    },
    forceFileDownload(response, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(url, title) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title)
        })
        .catch(() => console.log('error occured'))
    },
  }
  
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Kanit');
.footer {
  background-color: #333;
  color: #fff;
  padding: 30px 0;
}

#app{
  background-color: #f1f5f8;
  font-family:'Teachers';
   }
.footer h5 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.footer p {
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
}

.overlay::-webkit-scrollbar {
  width: 0px; /* Masquer la barre de défilement verticale */
  height: 0px; /* Masquer la barre de défilement horizontale */
}

.footer ul li a:hover {
  text-decoration: underline;
}
.list-choix::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement */
}
em{
  font-weight:bold;
  color:orange
}
/* Pour Firefox */
.list-choix {
    overflow: -moz-scrollbars-none; /* Masquer la barre de défilement */
    -ms-overflow-style: none; /* Masquer la barre de défilement pour Edge */
}
.detail:hover{
  background-color: rgb(255, 255, 255);
}
.card-image {
      position: relative; /* Position relative pour le positionnement absolu du texte */
      overflow: hidden;
      border-radius: 30px;

    }
  .btn-primary{
    background-color: orange;
    color: white;
  }
  .titre-color {
    transition: color 1s ease-in-out; /* Ajout de l'effet ease-in-out */
}
  .card-image img {
    width: 300px; /* Utilise toute la largeur disponible */
    height: 300px; /* Ajuste automatiquement la hauteur en fonction de la largeur */
    object-fit: cover;
    border-radius: 30px;
    transition: transform 0.3s;
  }
  .card-image:hover img {
      transform: scale(1.1);
      cursor: pointer;
    }

.choix{
margin-right: 120px;
opacity: 1;
}

.choix:hover{
  background-color: rgb(0, 0, 0);
  border-radius: 30px;
  padding-top: 3px;
}

.choix-titre{
  width:170px;
  color: white;
  font-weight: bold;
}
  .card-title {
    position: absolute; /* Position absolue pour superposer le texte sur l'image */
    bottom: 25px; /* Marge inférieure par rapport au bas de l'image */
    left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
    color: white; /* Couleur du texte */
    font-size: 24px; /* Taille de la police */
    font-weight: bold; /* Gras */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
  }
  .card-subtitle {
    position: absolute; /* Position absolue pour superposer le texte sur l'image */
    bottom: 15px; /* Marge inférieure par rapport au bas de l'image */
    left: 20px; /* Marge gauche par rapport au bord gauche de l'image */
    color: white; /* Couleur du texte */
    font-size: 24px; /* Taille de la police */
    font-weight: bold; /* Gras */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
  }
  .nav-link{
  color: white;
}
.navbar-brand{
  color: orange;
  font-size: 32px;
  font-family:'Caveat';
}
.navbar-brand:hover{
  color: orange;
  font-size: 32px;
}
.nav-link{
  font-weight: bold;
}
.titre{
  padding-top: 70px;
  font-size: 50px;
  color: rgb(0, 0, 0);
  font-family:'Teachers';
  text-align: center;
}
nav.scrolled {
    background-color: rgba(255, 255, 255, 0.9); /* Fond de la barre de navigation après le défilement */
}


.navbar-brand {
    margin: 0;
    color: orange
}


.nab-bg{
  height: 80vh;
  background-color:#f1f5f8;
  background-size: cover;
  position:absolute;
  top:0;
  left:0px;
  z-index: -1;
}
.bar-recherche-accueil{
  display: block;
}
.bar-recherche-accueil-petit{
  display: none;
}
.carte{
  display: block;
}
.carte-sm{
  display: none;
}
.mdr{
background-image:url('../assets/moi11.jpg');
background-size:cover;
background-position: center; 
}
@media screen and (max-width: 1268px) {
  .nab-bg{
  height: 80vh;
  background-color:#f1f5f8;
  background-size:cover;
  position:absolute;
  top:0;
  left:0px;
  z-index: -1;
}
  .carte{
  display: none;
}
.carte-sm{
  display: block;
}
.titre{
  padding-top: 40px;
  font-size: 35px;
  color: rgb(0, 0, 0);
  text-align: center;
  width: 90%;
  margin-left: 5%;

}
}

@media screen and (max-width: 768px) {
.titre{
  padding-top: 40px;
  font-size: 35px;
  color: rgb(0, 0, 0);
  text-align: start;
  width: 90%;
  margin-left: 5%;
}
.mdr{
  height: 180px;
}
}


.overlay {
  position: fixed;
  top: 0;
  left: -102%; /* Position initiale en dehors de l'écran */
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: left 0.5s ease; /* Transition de la propriété 'left' */
}

.overlay.active {
  left: 0; /* Position de la gauche de l'écran */
}

.overlay .content {
  padding: 20px;
}

.toggler {
  background: none;
  border: none;
  cursor: pointer;
}
.close-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
</style>